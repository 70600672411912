@font-face {
  font-family: "Adelle Sans CYR";
  src: url("../fonts/AdelleSansCYR.woff2") format("woff2"),
    url("../fonts/AdelleSansCYR.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adelle Sans CYR Sb";
  src: url("../fonts/AdelleSansCYRSb.woff2") format("woff2"),
    url("../fonts/AdelleSansCYRSb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adelle Sans CYR Sb";
  src: url("../fonts/AdelleSansCYR-Bold.woff2") format("woff2"),
    url("../fonts/AdelleSansCYR-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
