:root {
  --app-height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $mainFont;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background-color: #f9f9f9;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

a,
a:active {
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  text-decoration: none;
  transition: 0.3s;
}

h1 {
}

h2 {
}

h3 {
}

p {
  margin: 0;
}

svg {
  path,
  circle,
  line,
  polyline {
    transition: 0.3s;
  }
}

img {
  max-width: 100%;
}

.container {
  width: 1150px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) {
    width: 960px;
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    width: 720px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.hidden {
  overflow: hidden !important;

  @media (max-width: 991px) {
    padding-right: 0;
  }
}

hr {
  height: 1px;
  border: 0;
  border-bottom: 1px solid $black;
  margin: 0;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  color: $black;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  font-family: $mainFont;
  text-transform: uppercase;
  color: $white;
  gap: 10px;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox__checkmark {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 4px;
  transition: 0.3s;
}

.checkbox__checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: 0.3s;
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox input:checked ~ .checkbox__checkmark {
  background-color: $black;

  &:after {
    opacity: 1;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 17px;

  & > label {
    color: $black;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
  }

  & > input {
    width: 100%;
    height: 50px;
    border: 1px solid $black;
    transition: 0.2s ease-out;
    border-radius: 6px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-transform: uppercase;
    color: $black;

    &:focus {
      border-color: $black;
    }
  }
}
