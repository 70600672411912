.footer {
  margin-top: auto;
}

.footer__top {
  background-color: $greenBg;

  .container {
    flex-direction: row;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.footer__item {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  padding: 38px 0 50px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 60px;

  @media (max-width: 1024px) {
    padding-left: 30px;
  }

  @media (max-width: 767px) {
    border-right: 0;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
}

.footer__title {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  gap: 14px;
  color: $white;
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
}

.footer__text {
  padding-left: 64px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #d9d9d9;
  padding-right: 62px;

  @media (max-width: 1024px) {
    padding-right: 10px;
  }
}

.footer__link {
  color: #d9d9d9;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding-left: 62px;

  &:hover {
    color: $yellow;
  }
}

.footer__bottom {
  padding: 28px 0;

  .container {
    flex-direction: row;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
    }

    .logo {
      margin-right: 50px;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
}

.terms {
  color: $yellow;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  &:hover {
    color: $green;
  }
}

.copyright {
  margin-left: auto;
  font-weight: 400;
  font-size: 22px;
  line-height: 145%;
  color: rgba(0, 0, 0, 0.3);

  @media (max-width: 767px) {
    margin-left: 0;
  }
}
