.header {
  @media (max-width: 1024px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .container {
    flex-direction: row;
  }
}

.header__left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 100px 50px 30px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 60px 30px 20px 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding-top: 30px;
  }
}

.logo {
  display: flex;
}

.language {
  margin-left: auto;
  background-color: $white;
  min-width: 70px;
  height: 50px;
  color: $green;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 20px;
    height: 40px;
  }

  &:hover {
    color: #b69900;
    box-shadow: 0 0 10px 0 #b69900;
  }
}

.header__right {
  display: flex;
  width: 450px;
  flex-shrink: 0;
  align-items: center;
  padding: 100px 0 30px 50px;

  @media (max-width: 1024px) {
    padding: 60px 0 20px 30px;
    width: 320px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    padding-top: 30px;
    width: 50%;
    gap: 20px;
  }

  .btn {
    margin-left: auto;
  }
}

.header__phone {
  color: $green;
  font-size: 25px;
  line-height: 100%;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  &:hover {
    color: $yellow;
  }
}
