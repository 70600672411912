.offer {
  position: relative;

  .container {
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
}

.offer__left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.offer__right {
  width: 450px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
  }

  img {
    height: calc(100% - 171px);
    object-fit: cover;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      max-height: 350px;
    }
  }
}

.offer__title {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: 45px;
  padding-bottom: 37px;

  @media (max-width: 1360px) {
    padding-bottom: 47px;
  }

  @media (max-width: 1024px) {
    border-right: 0;
    padding-top: 30px;
  }

  @media (max-width: 767px) {
    font-size: 30px;
    border-right: 0;
    padding-bottom: 30px;
  }

  span {
    font-weight: 600;
    font-size: 60px;

    @media (max-width: 1360px) {
      font-size: 52px;
    }

    @media (max-width: 767px) {
      font-size: 36px;
    }
  }
}

.offer__right-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  padding-top: 75px;
  padding-bottom: 44px;
  padding-left: 50px;

  @media (max-width: 767px) {
    padding: 25px;
  }
}

.offer__descr {
  display: flex;
  flex-direction: column;
  background-color: $greenBg;
}

.offer__descr-text {
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: $white;
  padding: 50px 50px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  @media (max-width: 767px) {
    padding: 25px;
    font-size: 16px;
  }
}

.offer__descr-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #c5c5c5;
  padding: 50px;

  @media (max-width: 767px) {
    padding: 25px;
    font-size: 14px;
  }
}

.main {
  background: url(../images/main-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 100px;

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  .container {
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
}

.main__left {
  flex-grow: 1;
}

.main__right {
  width: 450px;
  flex-shrink: 0;
  background-color: $white;
  padding: 50px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 25px;
  }
}

.main__right-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: $greenBg;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    font-size: 32px;
  }
}

.main__text {
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: $white;
  padding: 50px 50px 34px;

  @media (max-width: 1360px) {
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    padding: 25px;
  }
}

.main__logo {
  margin-left: auto;
  margin-right: 50px;
  max-width: 230px;
  display: flex;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    margin-bottom: 40px;
    margin-right: 0;
    margin-left: 25px;
  }
}

.main__info {
  font-size: 16px;
  line-height: 150%;
  color: #c5c5c5;
  max-width: 400px;
  margin-left: auto;
  margin-right: 50px;

  @media (max-width: 1360px) {
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    padding: 25px;
    margin-right: 0;
  }
}

.main__right-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
