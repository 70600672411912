.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 0 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  flex-shrink: 0;
  height: 50px;
  border: 0;
  font-family: $mainFont;
  background: linear-gradient(270deg, #b69900 0%, #063 100%);
  border-radius: 99px;
  color: $white;

  @media (max-width: 767px) {
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
  }

  &:hover {
    box-shadow: 0 0 10px 0 #b69900;
  }
}
